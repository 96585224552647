import React from "react";

import MenuToggle from "./MenuToggle";

import styles from "./Header.module.css";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
// import AppLogoPath, {
//   ReactComponent as AppLogo,
// } from "../../assets/parrot.svg";
import AppIcon from "../../assets/LOGO-RIT-PICTOGRAMME-BLANC.png";
import Blur from "../FX/Blur";
import { useAuthUserContext } from "../../context/UserContext";


interface IProps {
  className?: string;
}

const Header: React.FC<IProps> = ({ className }) => {
  const location = useLocation();
  const showLogo = location.pathname !== "/";
  const { user } = useAuthUserContext();

  return (
    user ? 
    <div className={classnames(className, styles.Header)}>
      <MenuToggle className={styles.MenuToggle} />
      {showLogo && (
        <Blur offset={[5, -5]} scale={1.1} className={styles.LogoLink}>
          <Link to={"/account/me"}>
              <div className={styles.AvatarIco} style={{backgroundImage: `url(${'/avatars/'+user.avatar})`} } /> 
          </Link>
        </Blur>
      )}
    </div> : 
    <div className={classnames(className, styles.Header)}>
      <MenuToggle className={styles.MenuToggle} />
      {showLogo && (
        <Blur offset={[5, -5]} scale={1.1} className={styles.LogoLinkSignup}>
          <Link to={"/signup"}>
           <div  ><span>sign up</span><img src={AppIcon} className={styles.Logo} alt="Realities In Transition Logo" /></div>
          </Link>
        </Blur>
      )}
    </div>
  );
};

export default Header;
