import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
// import useRandomStatement from "../../hooks/useRandomStatement";
// import ColorShadow from "../FX/ColorShadow";
import styles from "./IntroductionPage.module.css";
// import StrokeSvgFile from "../../assets/stroke.svg";
import { useStatementsContext } from "../../context/StatementsContext";
import PageHeader from "../App/PageHeader";
import {
  ReactComponent as NextIcon,
} from "../../assets/icon_statement_next.svg";
import {
  ReactComponent as PrevIcon,
} from "../../assets/icon_statement_prev.svg";
import DarkPage from "../UserInterfaceElements/DarkPage";

const IntroductionPage: React.FC = () => {
  const { user } = useAuthUserContext();
  const { stack } = useStatementsContext();

  const  stepStack = [
    {
      title: "XR STATEMENTS", 
      description: "Browse and bookmark statements to create your personal XR Manifesto.",
      screen: "/introduction/01-XRStatements.gif"
    },
    {
      title: "MANAGE YOUR MANIFESTO", 
      description: "Arrange your bookmarks. Statements above the line are part of your manifesto.",
      screen: "/introduction/02-ManageManifesto.gif",
    },
    {
      title: "COMMON MANIFESTO", 
      description: `With your manifesto, you participate in the development of the Common Manifesto, the average of all the contributions of the community.`,
      screen:  "/introduction/03-CommonManifesto.png",
    },
    {
      title: "CONTRIBUTE!", 
      description: "Share your personal statements or quote inspiring sources.",
      screen: "/introduction/04-Contribute.png",
    }
  ]; 

  const [currentStep, setCurrentStep] = useState(0);




  const doStep = (stepSize:number) => {
    setCurrentStep(currentStep + stepSize);
  }


  return (
    <DarkPage >
      <div className={styles.IntroductionStepGrid}>
        <div className={styles.TextContent} >
          <PageHeader className={styles.PageHeader} style={{color:"rgb(123, 235, 180)",fontStyle: "italic"}}>
            Tutorial<br/><br/>
          </PageHeader>
          <PageHeader className={styles.PageHeader}>
            {stepStack[currentStep].title}
          </PageHeader>
          <p>
            {stepStack[currentStep].description}
          </p>
        </div>
        {
          currentStep > 0 ? <a href="#" className={styles.PreviousStep} onClick={e=>{e.preventDefault(); doStep(-1)}}>
                      <PrevIcon />
                    </a> : null
        }
        <img className={styles.StepIllustration} src={stepStack[currentStep].screen} />
        {
          currentStep < stepStack.length - 1  ? <a href="#" className={styles.NextStep} onClick={e=>{e.preventDefault(); doStep(+1)}}>
                      <NextIcon />
                    </a>: <Link to={`/statement/${stack?.current?.id || 50}`} className={styles.NextStep} >
                      <NextIcon className={styles.NextStep}/>
                    </Link>
        }
      </div>
    </DarkPage>
  );
};

export default IntroductionPage;
