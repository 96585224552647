import React, { FormEvent, useState } from "react";
import { useAuthUserContext } from "../../context/UserContext";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";

import styles from "./RequestResetPage.module.css";
import Button from "../UserInterfaceElements/Button";
import DarkPage from "../UserInterfaceElements/DarkPage";

const RequestResetPage: React.FC = () => {
  const [email, setEmail] = useState<string>();
  const { API, error } = useAuthUserContext();
  const [didRequest, setDidRequest] = useState(false);

  const requestReset = async (evt: FormEvent) => {
    evt.preventDefault();
    if (!API) {
      return;
    }
    await API.post("/user/password/forgot", { email });
    setDidRequest(true);
  };

  return (<DarkPage>
    <div className={styles.RequestResetPage}>
      <PageHeader>Forgot password?</PageHeader>
      <ContentSection>
      {error && <p>{error}</p>}
      {didRequest ? <>
      <p>Reset requested, check your inbox.</p>
      <p>If you did not receive a reset e-mail, check your spam folder and make sure to 
        {` `}<a href="https://blog.hubspot.com/marketing/email-whitelist" target="_blank" rel="noreferrer" style={{display: "inline", textDecoration:"underline"}}>whitelist noreply@cxrm.eu</a>.
      </p>
      </>: 
      <form className={styles.Form} onSubmit={(evt) => requestReset(evt)}>
        <input
          type="text"
          className={styles.EmailInput}
          placeholder="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
        <Button type="submit">Get reset link</Button>
      </form>}
      </ContentSection>
    </div>
    </DarkPage>
  );
};

export default RequestResetPage;
