import React, { useEffect, useState } from "react";
import styles from "./AvatarPicker.module.css";
import cx from "classnames";
import PageHeader from "../App/PageHeader";
import { useAuthUserContext } from "../../context/UserContext";
import Avatar from "./Avatar";
import { Link, useHistory } from "react-router-dom";
import DarkPage from "../UserInterfaceElements/DarkPage";

interface IProps {}

const AvatarPicker: React.FC<IProps> = () => {
  const { API, user, updateMyProfile } = useAuthUserContext();
  const [avatars, setAvatars] = useState<string[]>([]);

  const history = useHistory();

  const pickAvatar = async (avatar: string) => {
    await updateMyProfile({ avatar });
    history.push('/account/me');
  };

  useEffect(() => {
    API?.get("/avatars").then((res) => {
      res.data && setAvatars(res.data);
    });
  }, [API]);

  return (
    <div className={styles.AvatarPicker}>
      {avatars.map((avatar, idx) => (
        <span
          key={idx}
          className={cx(styles.Avatar, {
            [styles.Current]: avatar === user?.avatar,
          })}
          onClick={() => pickAvatar(avatar)}
        >
          <Avatar imageName={avatar} />
        </span>
      ))}
    </div>
  );
};

export const AvatarPickerPage: React.FC<IProps> = (props) => {
  return (
    <DarkPage>
      <div className={styles.AvatarPickerPage}>
          <PageHeader>Change your Avatar</PageHeader>
          <Link to="/account/me">back to your account</Link>
          <AvatarPicker />
      </div>
    </DarkPage>
  );
};

export default AvatarPicker;
