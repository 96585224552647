import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
// import useRandomStatement from "../../hooks/useRandomStatement";
// import ColorShadow from "../FX/ColorShadow";
import styles from "./HomePage.module.css";
// import StrokeSvgFile from "../../assets/stroke.svg";
import { useManifestoContext } from "../../context/ManifestoContext";
import { useStatementsContext } from "../../context/StatementsContext";
import Button from "../UserInterfaceElements/Button";
import ButtonGroup from "../UserInterfaceElements/ButtonGroup";
import DarkPage from "../UserInterfaceElements/DarkPage";

const HomePage: React.FC = () => {
  const { user } = useAuthUserContext();
  const { myManifesto } = useManifestoContext();
  // const randomStatement = useRandomStatement();
  const { stack } = useStatementsContext();
    
  if (user) {
    // if (!user.name || !user.avatar) {
    //   return <Redirect to="/manifesto/me" />;
    // }
    // if (myManifesto?.statements.length) {
    //   return <Redirect to="/manifesto/me" />;
    // }

    // return <Redirect to={`/statement/${stack?.current?.id || 109}`} />;
    return <Redirect to={`/introduction`} />;
  }

  return (<DarkPage >
      <div className={styles.HomePage}>
        <p></p>
        <img className={styles.Parrot} src={"/join_us.svg"} alt="Realities in transition" />
        <p></p>
        <ButtonGroup layout="horizontal" >
          <Button
            to={`/about`}
            // className={styles.BrowseButton}
            className={styles.HomePageButton}
          >
            About
          </Button>
          <Button
            to={`/signup`}
            className={styles.HomePageButton}
          >
            Sign Up
          </Button>
          <Button
            to={`/login`}
            className={styles.HomePageButton}
          >
            Log In
          </Button>
        </ButtonGroup>
        <p></p>
      </div></DarkPage>
  );
};

export default HomePage;
