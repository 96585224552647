import React from "react";

import styles from "./AboutPage.module.css";
import PageHeader from "./PageHeader";
import DarkPage from "../UserInterfaceElements/DarkPage";

const AboutPage: React.FC = () => {
  return (
    <DarkPage >
      <div className={styles.AboutPage}>
        <PageHeader className={styles.AboutPageHeader}>C.XR.M</PageHeader>
        <h3 className={styles.AboutPageSubHeader}>The Critical XR Manifesto<br/>by Realities In Transition</h3>
        <p>
        Realities in Transition is a movement exploring alternative, inclusive and sustainable XR production.
        </p>
        <p>
        We experiment and support new narratives and creative processes while aggregating the community of European professionals to address cultural and social challenges raised by XR
        </p>

        <img src="/cofunded_by_eu.svg" alt="Co-funded by the European Union" style={{marginLeft:"-6px",marginRight:"20%"}} />

        {/* <h3>Common Manifesto</h3>

        <p>The Common Manifesto lists the most selected statements on the CXRM platform. <br/>
        You and other users compose their own personal XR Manifesto by selecting and contributing statements.
        The most popular statements are added to the Common Manifesto by representation.</p>

        <h3>Contribute Statement</h3>

        <p>Contribute to a broad perspective on XR, and add your statement or sourced quotation to the platform. 
        Sign up and you can participate. </p>

        <ol style={{color:"linen"}}>
          <li>After activiting your account, login on the platform</li>
          <li>Open the menu and choose "Contribute statement"</li>
          <li>Add your statement</li>
          <li>Optionally, add the Source publication, Source author and/or Source link for reference</li>
          <li>Add your statement to the pool</li>
        </ol>

        <p>You statement is added to <a style={{color:"linen", textDecoration:"ubnderline", display:"inline"}} href="/manifesto/me">your Manifesto</a>, and is available for CXRM contributers to add to their XR Manifesto.</p>
        */}
      </div> 
    </DarkPage>
  );
};

export default AboutPage;
