import React from "react";

import styles from "./PageHeader.module.css";
import cx from "classnames";

interface IProps {
  className?: string;
  style?:React.CSSProperties;
}

const PageHeader: React.FC<IProps> = ({ children, className, style={} }) => {
  return <h2 className={cx(styles.PageHeader, className)} style={style}>{children}</h2>;
};

export default PageHeader;
