import React, { useEffect } from "react";

import styles from "./Menu.module.css";
import classnames from "classnames";
import { useMenuContext } from "../../context/MenuContext";
import { useAuthUserContext } from "../../context/UserContext";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "../UserInterfaceElements/Button";
// import Avatar from "../User/Avatar";
// import useRandomStatement from "../../hooks/useRandomStatement";
import { useStatementsContext } from "../../context/StatementsContext";
import { BackFxProvider } from "../../context/BackFxContext";
import cx from "classnames";

interface IProps {
  className?: string;
}

const Menu: React.FC<IProps> = ({ className, children }) => {
  const { showMenu, setShowMenu } = useMenuContext();
  const { loggedIn, user } = useAuthUserContext();
  // const match = useRouteMatch<{ id: string }>("/statement/:id");
  // let curStatementId = undefined;
  // if (match?.params.id) {
  //   curStatementId = parseInt(match.params.id);
  // }
  // const randomStatement = useRandomStatement(curStatementId);
  const { stack } = useStatementsContext();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setShowMenu(false);
    });
  }, [history, setShowMenu]);

  return (
    <div
      className={classnames(className, styles.Menu, {
        [styles.Show]: showMenu,
      })}
      // onClick={() => setShowMenu(false)}
    >
    <BackFxProvider backdropClassName={styles.Backdrop}>
      <div className={styles.List}>
        <div>
          <Button  className={styles.MenuButton} to={`/statement/${stack?.current?.id ?? 1}`}    altStyle={true}
          >
            Browse Statements
          </Button>
        </div>
        <div>
          <Button  className={styles.MenuButton} to={`/common-manifesto`}   altStyle={true}
          >
            Common Manifesto
          </Button>
        </div>
        {loggedIn ? (
          <>
            <div>
              <Button  className={styles.MenuButton} to="/manifesto/me"   altStyle={true}>
                My Manifesto
              </Button>
            </div>
            <div>
              <Button  className={styles.MenuButton} to="/statement/add"  altStyle={true}>
                Contribute Statement
              </Button>
            </div>
          </>
        ) : null}
        <div>
          <Button  className={styles.MenuButton} to="/about"  altStyle={true}
          >
            About
          </Button>
        </div>
        {loggedIn ? (
          <>
            <div className={styles.AccountMenuItem}>
              <Button  className={styles.MenuButton} to="/account/me" altStyle={true}> 
                Account
              </Button>
            </div>
          </>
        ) : null}
        {!loggedIn ? (
          <div className={styles.AccountMenuItem}>
            <Button  className={styles.MenuButton} to="/login" altStyle={true}>
              Account
            </Button>
          </div>
        ):null}
        {loggedIn && user && user.moderator ? (
           <div>
             <Button  className={styles.MenuButton} to="/moderate" altStyle={true}>
              Moderate Content
             </Button>
           </div>
        ):null}
      </div>
      </BackFxProvider>
    </div>
  );
};

export default Menu;
