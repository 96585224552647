import { useCallback, useState } from "react";
import { IStatement, useStatementsContext } from "../context/StatementsContext";
import { useAuthUserContext } from "../context/UserContext";

const useAddStatement = () => {
  const { setStatements } = useStatementsContext();
  const [statement, setStatement] = useState<IStatement>();
  const { API } = useAuthUserContext();

  const add = useCallback(
    (text: string, source?: string, sourceLink?: string, sourceAuthor?: string) => {
      API!.post(`/statement/add`, { text, source, sourceLink, sourceAuthor }).then((res) => {
        const newStatement: IStatement = res.data;
        setStatements((statements: IStatement[]) => {
          if (statements) {
            return [...statements, newStatement];
          } else {
            return [newStatement];
          }
        });
        setStatement(newStatement);
      });
    },
    [API, setStatements]
  );

  return { statement, add };
};

export default useAddStatement;
