import React from "react";

import styles from "./StatementRow.module.css";
import { IStatement } from "../../context/StatementsContext";
import { Link } from "react-router-dom";
import { useManifestoContext } from "../../context/ManifestoContext";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

interface IProps {
  statement: IStatement;
  textClassName?: string;
  isPublic?: boolean;
}

const StatementRow: React.FC<IProps> = ({ statement, textClassName, isPublic=null }) => {
  const { remove, makeBookmark } = useManifestoContext();
  // const history = useHistory();

  const confirmBookmark = () => {
    if (window.confirm("Move this statement from your manifesto to your bookmarks?")) {
      makeBookmark(statement);
    }
  };
  const confirmDelete = () => {
    if (window.confirm("Remove this statement from your bookmarks?")) {
      remove(statement);
    }
  };

  return (
    <>
      <div className={styles.Buttons}>
        {/* <CommentsButton
          statement={statement}
          className={styles.CommentsButton}
          onClick={() =>
            history.push(`/statement/${statement.id}#comments=true`)
          }
        />
        <ShareButton className={styles.ShareButton} /> */}
        {console.log(`statement.yours [${statement.yours}]`)}
        {
          isPublic === null ? null : 
          !isPublic && statement.yours === 0 ? null : 
          <CloseIcon
            className={styles.RemoveButton}
            onClick={() => isPublic ? confirmBookmark() : confirmDelete()}
          />
        }
      </div>
      <Link to={`/statement/${statement.id}`} className={textClassName}>
        {statement.text}
      </Link>
    </>
  );
};

export default StatementRow;
