import React from "react";

import { useAverageManifestoContext } from "../../context/AverageManifestoContext";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import useAverageManifesto from "../../hooks/useAverageManifesto";

import styles from "./AverageManifestoPage.module.css";

//import { ReactComponent as AddToAverageManifestoIcon } from "../../assets/icon_addToManifesto.svg";
import StatementRow from "./../UserInterfaceElements/StatementRow";
import PageHeader from "../App/PageHeader";
import ShareButton from "../Share/ShareButton";


const AverageManifestoPage: React.FC = () => {
  const { add, contains } = useAverageManifestoContext();
  const { user: loggedInUser } = useAuthUserContext();
  const { averageManifesto } = useAverageManifesto();


  if (!averageManifesto) {
    return <div>loading....</div>;
  }

  let publicAverageManifesto = averageManifesto.statements || [];
 

  const makeStatement = (statement: IStatement, idx: number) => (
    <div key={idx} className={styles.AverageManifestoItem}>
      <StatementRow
        statement={statement}
        linkToStatementPage={true}
        // textClassName={styles.StatementText}
      />
      {/* {loggedInUser && (
        <span>
          {contains(statement) ? (
            <>in your averageManifesto</>
          ) : (
            <span onClick={() => add(statement)}>
              <AddToAverageManifestoIcon />
            </span>
          )}
        </span>
      )} */}
    </div>
  );


  return (
    <div className={styles.AverageManifestoPage}>
      <PageHeader className={styles.PageHeader}>
        Common Manifesto
      </PageHeader>
      <ShareButton
        text={`Check the Critical XR Manifesto`}
        url={window.location.href}
        className={styles.ShareButton}
      />
      {publicAverageManifesto.map(makeStatement)}
    </div>
  );
};

export default AverageManifestoPage;
