import React from "react";

import styles from "./StatementRow.module.css";
import { IStatement } from "../../context/StatementsContext";
import { Link /*, useHistory */} from "react-router-dom";
//import { useManifestoContext } from "../../context/ManifestoContext";

interface IProps {
  statement: IStatement;
  textClassName?: string;
  linkToStatementPage?: boolean;
}

const StatementRow: React.FC<IProps> = ({ statement, textClassName=styles.StatementText, linkToStatementPage }) => {
  //const { remove } = useManifestoContext();
  //const history = useHistory();

  // const confirmDelete = () => {
  //   if (window.confirm("Remove this statement from your manifesto?")) {
  //     remove(statement);
  //   }
  // };

  return (
      /* <div className={styles.Buttons}>
        <CommentsButton
          statement={statement}
          className={styles.CommentsButton}
          onClick={() =>
            history.push(`/statement/${statement.id}#comments=true`)
          }
        />
        <ShareButton className={styles.ShareButton} />
        <CloseIcon
          className={styles.RemoveButton}
          onClick={() => confirmDelete()}
        />
      </div> */
      <Link to={ linkToStatementPage ? `/statement/${statement.id}` : "#" } className={ textClassName }>
        <span className={ statement.deleted ? styles.StatementDeleted : ""}>{statement.text}</span>
      </Link>
  );
};

export default StatementRow;
