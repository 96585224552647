import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  IStatement,
  OptionalIStatement,
  StatementId,
} from "../context/StatementsContext";
import randomInt from "../randomInt";

const randomItem: <T>(arr: T[], excluding?: T) => T | undefined = (
  arr,
  excluding
) => {
  if (!arr.length) {
    return undefined;
  }
  let result;
  do {
    result = arr[randomInt(arr.length)];
  } while (result === excluding);
  return result;
};

export interface StatementsStack {
  current: OptionalIStatement;
  next: OptionalIStatement;
  previous: OptionalIStatement;
}

const useStatementsStack = (statements: IStatement[]) => {
  const [current, setCurrent] = useState<IStatement>();
  const [next, setNext] = useState<IStatement>();
  const [previous, setPrevious] = useState<IStatement>();
  const [stack, setStack] = useState<IStatement[]>([]);
  const [index, setIndex] = useState<number>(0);
  const history = useHistory();
  const [currentIdInView, setCurrentIdInView] = useState<StatementId>();

  useEffect(() => {
    return history.listen((location) => {
      const match = location.pathname.match(/\/statement\/(\d+)/);
      if (match && match[1]) {
        const curId = parseInt(match[1]);
        if (curId > 0) {
          setCurrentIdInView(curId);
        }
      }
    });
  }, [history]);

  useEffect(() => {
    if (!currentIdInView || !statements) {
      return;
    }
    if (current?.id === currentIdInView) {
      // as it should be, do nothing
    } else if (next?.id === currentIdInView) {
      setIndex((idx) => idx + 1);
    } else if (previous?.id === currentIdInView) {
      setIndex((idx) => idx - 1);
    } else {
      // replace the next in the stack and go there
      const curStatement = statements.find(
        (statement) => statement.id === currentIdInView
      );
      if (curStatement) {
        setStack((stck) => [...stck.slice(0, stck.length - 1), curStatement]);
        setIndex((idx) => idx + 1);
        // console.log(
        //   "useStatementStack"+
        //   `\n\tstatements:${statements.length}`+
        //   `\n\tstack:`+
        //   `\n\t\t${stack}`+
        //   `\n\tidx:${index}`
        // )
      // }else{
      //   console.log(
      //     "useStatementStack"+
      //     `\nNO STATEMENT`+
      //     `\n\tstatements:${statements}`+
      //     `\n\tstack:${stack}`+
      //     `\n\tidx:${index}`
      //   )
      }
    }
  }, [currentIdInView, statements, current, next, previous]);

  useEffect(() => {
    // init the stack
    if (statements) {
      let cur = randomItem(statements)!;
      setStack([
        cur,
        randomItem(statements, cur)!,
        randomItem(statements, cur)!,
      ]);
      setIndex(1);
    }
  }, [statements]);

  useEffect(() => {
    if (!stack || !index || !statements) {
      return;
    }
    setCurrent(stack[index]);
    if (index === 1) {
      // console.log("first in stack for index "+index, stack);
      // prepend an item
      //   setNext(stack[index + 1])
      const randomPrev = randomItem(statements, stack[index])!;
      setStack((stck) => [randomPrev, ...stck]);
      setIndex(2);
    } else if (index === stack.length - 1) {
      // console.log("last in stack for index "+index, stack);
      // append an item
      //   setPrevious(stack[index - 1]);
      const randomNext = randomItem(statements, stack[index])!;
      //   setNext(randomNext);
      setStack((stck) => [...stck, randomNext]);
    } else {
      // console.log("Not first or last in stack for index "+index, stack);
      setPrevious(stack[index - 1]);
      setNext(stack[index + 1]);
    }
  }, [statements, stack, index]);



  /* useEffect(() => {
    if (!stack || !index || !statements) {
      return;
    }
    setCurrent(stack[index]);
    if (index === 0) {
      console.log("first in stack");
      // prepend an item
      setNext(stack[index + 1])
      //const randomPrev = undefined; // randomItem(statements, stack[index])!;
      setPrevious(undefined);
      setStack((stck) => [...stck]);
      setIndex(1);
    } else if (index === stack.length - 1) {
      console.log("last in stack");
      // append an item
      setPrevious(stack[index - 1]);
      //const randomNext = undefined; //randomItem(statements, stack[index])!;
      setNext(undefined);
      setStack((stck) => [...stck]);
    } else {
      console.log("Not first or last in stack");
      setPrevious(stack[index - 1]);
      setNext(stack[index + 1]);
      setStack((stck) => [...stck]);
    }
  }, [statements, stack, index]);*/

  return { current, next, previous };
};

export default useStatementsStack;
