import React, { useEffect, useState } from "react";

import styles from "./StatementPage.module.css";
// import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";
import StatementRow from "./../UserInterfaceElements/StatementRow";
import {
  useStatementsContext,
} from "../../context/StatementsContext";
import { removeModerationClass } from "../Moderation/moderationFunctions";
import Breadcrumb from "../UserInterfaceElements/Breadcrumb";


const ModerateStatementsPage: React.FC = () => {
  
  const { statements } = useStatementsContext();
  const history = useHistory();
  statements.sort((a, b) => (a.updated < b.updated) ? -1 : ((a.updated > b.updated) ? 1 : 0)).reverse();
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([<></>]);

  //const [statements, setStatements] = useState<IStatement[]>([]);
  
  useEffect(() => {
    window.location.pathname.substring(0, 9) === "/moderate" && setBreadcrumb([
      <Link to="/moderate/" >Moderation</Link>
    ]);
    statements.sort((a, b) => (a.updated < b.updated) ? -1 : ((a.updated > b.updated) ? 1 : 0)).reverse();
    const unlisten = history.listen((location, action) => {
      console.log('Route changed', { location, action });
      // Apply route change logic, i.e. dispatch to store
      if(location.pathname.substring(0, 9) !== '/moderate'){
        removeModerationClass();
      }
    });
    return unlisten;
  }, [statements]);

  return (
    <div className={styles.ModerateStatementsPage}>
      <Breadcrumb linkStack={breadcrumb} />
      <PageHeader>Moderate Statements</PageHeader>
      <ContentSection>
        <p style={{color: "#FAF0E6" }}>Edit & remove statements from the app.</p>
        <table style={{"borderSpacing": "0px"}}>
          {
            statements.map((statement, index) => {
            const updatedDate = new Date(statement.updated);
              return <>
                  <tr key={index} onClick={e => history.push(`/moderate/statement/${statement.id}/edit`)}>
                    <td colSpan={3}><span style={{color:"#FAF0E6", fontWeight:800}}>{(statements.length - index)}.</span><StatementRow 
                      statement={statement} linkToStatementPage={false}/></td>
                  </tr>
                  <tr>
                    {/* <td>{statement.submittedBy}</td> */}
                    <td  style={{color: "#FAF0E6" }}>{updatedDate.toLocaleDateString("nl-NL")}</td>
                    <td align="right"><Link to={`/moderate/${statement.id}/reactions`} style={{marginTop:0}}>reactions</Link></td>
                  </tr>
                  <tr>
                    <td><br/><br/></td>
                  </tr>
                </>
              }
            )
          }
        </table>

      </ContentSection>
    </div>
  );
};

export default ModerateStatementsPage;
